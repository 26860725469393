<template>

    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true" :model="search">
                <el-form-item prop="title">
                    <el-input v-model="search.title" placeholder="请输入关键字" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getList(data.size)">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <el-button type="primary" @click="toSearch">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        添加城市
                    </el-button>

                    <!-- <el-button class="block" @click="del">批量删除</el-button> -->


                    <el-button type="primary" @click="toggleRowExpansion(true)">
                        全部展开
                    </el-button>

                    <el-button type="danger" @click="toggleRowExpansion(false)">
                        全部折叠
                    </el-button>

                </el-form-item>
            </el-form>
        </div>

        <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
            :get-list="getList" stripe border ref="FanfuTable">

            <el-table-column show-overflow-tooltip prop="id" label="ID" align="left" type="" />

            <el-table-column show-overflow-tooltip label="城市名称" align="left">
                <template slot-scope="scope">
                    <span type="expand">

                        <i class="el-icon-location" v-if="scope.row.areaType===1"></i>
                        <i class="el-icon-location-outline" v-if="scope.row.areaType===2"></i>
                        <i class="el-icon-place" v-if="scope.row.areaType===3"></i>
                        {{ scope.row.areaName }}</span>
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip prop="areaType" label="城市级别" align="center">
                <template slot-scope="scope">
                    <el-button type="primary" roundsize="mini" round v-if="scope.row.areaType===1"> 省份
                    </el-button>
                    <el-button type="danger" roundsize="mini" round v-if="scope.row.areaType===2"> 城市
                    </el-button>
                    <el-button type="warning" roundsize="mini" round v-if="scope.row.areaType===3"> 区县
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="AreaCode" label="城市编码(区号)" align="center" />

            <el-table-column show-overflow-tooltip prop="order" label="排序" align="center" min-width="70" />


            <el-table-column show-overflow-tooltip label="操作" width="210" align="center">
                <template slot-scope="scope">
                    <div class="operate">
                        <el-button @click="view(scope.row)">
                            查看
                        </el-button>
                        <el-button type="primary" @click="edit(scope.row)">
                            编辑
                        </el-button>
                        <el-button type="danger" @click="del(scope.row)">
                            删除
                        </el-button>
                    </div>
                </template>
            </el-table-column>

        </Fanfu-Table>

        <Search ref="searchRef" v-model="search" @refresh="getList(data.size)" />

    </div>
</template>

<script>
    import Search from './components/AreaSearch'


    import resizeDetector from 'element-resize-detector'


    const list = [{
            id: '2-0',
            areaName: '天津市',
            areaType: 1,
            order: "111",
            AreaCode: "0001",
            children: [{
                    id: '2-1',
                    areaName: '天津市',
                    areaType: 2,
                    order: "111",
                    AreaCode: "0001",
                    children: [{
                        id: '2-1-1',
                        areaName: '西青区',
                        areaType: 3,
                        order: "111",
                        AreaCode: "0001",
                    }, {
                        id: '2-1-2',
                        areaName: '津南区',
                        areaType: 3,
                        order: "111",
                        AreaCode: "0001",
                    }, ]
                },

            ]
        },



        {
            id: '1',
            areaName: '北京市',
            areaType: 1,
            order: "111",
            AreaCode: "0001",

        },

    ]

    export default {
        components: {
            Search,

        },
        data() {
            return {
                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },
                loading: false,
                select: [],
                data: {
                    list,
                    page: 1,
                    size: 15,
                    total: this.AllTotal()
                }
            }
        },
        created() {
            this.getList();
        },
        methods: {

            toggleRowExpansion(val) {
                this.$refs.FanfuTable.toggleRowExpansion(val);
                this.modifyLayout();
            },

            AllTotal() {
                let a = list.length;
                for (let i = 0; i < list.length; i++) {
                    var dic = list[i];
                    if (dic.hasOwnProperty("children")) {
                        a = a + dic.children.length
                    }
                }
                return a;
            },

            getList(size) {

                if (size) {
                    this.data.page = 1
                    this.data.size = size
                }
                this.loading = true
                this.select = []
                this.$nextTick(() => {
                    const start = this.data.size * (this.data.page - 1)
                    const end = start + this.data.size
                    this.data.total = list.length
                    const data = []
                    for (let i = start; i < end; i++) {
                        if (list[i]) {
                            data.push(list[i])
                        }
                    }
                    this.data.list = data
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)

                    setTimeout(() => {
                        this.modifyLayout();
                    }, 1100)

                })

            },
            view(row) {
                console.log(row)
            },
            edit(row) {
                console.log(row)
            },
            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                            }, 3000);
                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    });
                });
            },


            toSearch() {
                this.$refs.searchRef.open()
            },


            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;


                    var a = 141 + 60 - 35 + height;
                    if ($(".level_indexI .FanfuTableBox")[0]) {
                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');
                    }


                    var b = 141 + 60 + 33 + height;
                    if ($(".level_indexI .el-table")[0]) {
                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');
                    }

                });

            }

        },

        mounted() {
            this.modifyLayout();
        },
        created() {
            this.modifyLayout();
        }

    }
</script>





<style lang="scss" scoped>
    .el-button--success {
        color: #ffffff;
        background-color: #5BD995;
        border-color: #5BD995;
    }
</style>


<style scoped>
    /*  .level_indexI .FanfuTableBox {
    border: 1px blue dotted;
    box-sizing: border-box;
  } */
    /*  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini { */

    /*  .level_indexI .el-table {
    border: 1px red dotted;
    box-sizing: border-box;
  } */
</style>
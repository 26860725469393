<template>
    <el-dialog :close-on-click-modal="false" title="新增城市" :visible.sync="show" width="500px" class="selfInputBox selfInputBoxIV">
        <el-form v-if="formShow" ref="form" :model="value" label-width="80px">



            <el-form-item label="城市名称" prop="title">
                <el-input v-model="value.title" placeholder="请输入城市名称" />
            </el-form-item>

            <el-form-item label="城市级别" prop="name1">
                <el-select v-model="valueSEL" placeholder="请选择城市级别">
                    <el-option v-for="item in options" :key="item.valueKEY" :label="item.label" :value="item.valueKEY">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="城市编号" prop="num">
                <el-input v-model="value.num" placeholder="请输入城市编号(区号)" />
            </el-form-item>

            <el-form-item label="父级编号" prop="numP">
                <el-input v-model="value.numP" placeholder="请输入父级地理编号" />
            </el-form-item>

            <el-form-item label="城市经度" prop="jindu">
                <el-input v-model="value.jingdu" placeholder="请输入城市坐标中心点经度" />
            </el-form-item>

            <el-form-item label="城市纬度" prop="weidu">
                <el-input v-model="value.weidu" placeholder="请输入城市坐标中心点维度" />
            </el-form-item>

            <el-form-item label="地理编号" prop="dili">
                <el-input v-model="value.dili" placeholder="请输入地理编号" />
            </el-form-item>

            <el-form-item label="排序号" prop="order">
                <el-input v-model="value.order" placeholder="请输入排序号" />
            </el-form-item>






        </el-form>


        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="refresh">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                show: false,
                formShow: false,


                options: [{
                    valueKEY: '选项1',
                    label: '省'
                }, {
                    valueKEY: '选项2',
                    label: '市'
                }, {
                    valueKEY: '选项3',
                    label: '县'
                }, ],
                valueSEL: '',






            }
        },
        methods: {
            open() {
                this.formShow = true
                this.show = true
            },
            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },




        }
    }
</script>

<style>
    /*  .selfInputBoxIV .el-input__inner[placeholder="请选择城市级别"] {
    border: 1px red solid !important;
    box-sizing: content-box !important;
  } */

    /*  .selfInputBoxIV .el-input__suffix {
       border: 1px red solid !important;
    box-sizing: content-box !important;
    right: -10px;
  } */
</style>